@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.end-section {
  background-color: transparent;
}

.end-section .section {
  background-color: transparent;
  height: fit-content;
  border-radius: 20px;
  font-family: "Poppins";
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 80px 40px;
}

.end-section .section-content {
  text-align: center;
  font-family: "Poppins";
  margin: 0% 20%;
  color: #fff;
  padding: 25px 0px;
}

.end-section .section-content h3 {
  font-size: 38px;
  font-weight: 400;
  margin: 0px;
  color: rgb(255, 255, 255);
  text-shadow: 4px 4px 8px #00000090;
}

.end-section .section-content p {
  font-size: 16px;
  font-weight: 200;
  padding: 25px 0px;
}

.end-section .contact-button:hover {
  background-color: #444;
  color: #fff;
  cursor: pointer;
}

.end-section .ant-btn-default {
  font-family: "Poppins" !important;
  font-size: 16px;
  border-color: #666edd !important;
  background-color: transparent;
  color: #666edd;
  border: 1px solid #666edd !important;
  border-radius: 20px;
  padding: 15px 20px !important;
  height: auto;
}

.end-section .ant-btn-default:hover {
  background-color: #666edd !important;
  color: white !important;
  -webkit-transition: background-color 500ms linear;
  -ms-transition: background-color 500ms linear;
  transition: background-color 500ms linear;
}

@media only screen and (max-width: 1200px) {
  .end-section .section-content {
    margin: 0 15px;
  }

  .end-section .section-content h3 {
    font-size: 36px;
    text-shadow: 9px 9px 18px #00000090;
  }

  .end-section .section-content p {
    font-size: 14px;
  }

  .end-section .ant-btn-default {
    font-size: 14px;
    border-radius: 25px;
    padding: 15px 20px !important;
  }
}

@media only screen and (max-width: 992px) {
  .end-section .section-content {
    margin: 0 10%;
  }

  .end-section .section-content h3 {
    font-size: 28px;
    text-shadow: 16px 16px 32px #00000090;
  }

  .end-section .section-content p {
    font-size: 18px;
  }

  .end-section .ant-btn-default {
    font-size: 18px;
    border-radius: 30px;
    padding: 15px 20px !important;
  }
}

@media only screen and (max-width: 768px) {
  .end-section .section-content {
    margin: 0 5px;
  }

  .end-section .section-content h3 {
    font-size: 24px;
    text-shadow: 20px 20px 40px #00000090;
  }

  .end-section .section-content p {
    font-size: 20px;
  }

  .end-section .ant-btn-default {
    font-size: 20px;
    border-radius: 35px;
    padding: 15px 20px !important;
  }
}

@media (max-width: 300px) {
  .end-section .ant-btn-default {
    font-size: 16px;
    border-radius: 35px;
    padding: 12px 16px !important;
  }

  .end-section .section-content h3 {
    font-size: 20px;
    text-shadow: 20px 20px 40px #00000090;
  }

  .end-section .section-content p {
    font-size: 16px;
  }
}