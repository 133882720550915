@font-face {
  font-family: 'Bubble';
  src: url('../fonts/PolanCanIntoGlassMakings.otf') format('opentype');
  /* You can include additional src declarations for different font formats (e.g., woff, woff2) for better browser compatibility */
}

.home {
  background-color: transparent;
}

.home .spacer {
  padding: 25px;
}

@media (max-width: 782px) {
  .home .spacer {
    padding: 15px;
  }

}

.home .title-section {
  /* min-height: 80vh; */
  border-radius: 20px;
  box-shadow: 0 4px 50px rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.75);
  text-align: center;
  padding: 120px 300px;

  background-image: url("/src/Assets/Images/background t.jpg");
  background-blend-mode: multiply;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}


.home .title h1 {
  /* background: linear-gradient(45deg, #666EDD, #9E6BFF);
  background-clip: text;
  -webkit-background-clip: text; */
  color: white;
  font-size: 90px;
  text-align: center;
  /* font-weight: 350; */
  font-family: 'Poppins', sans-serif;
  margin: 0;
  line-height: 90px;
}

@media (max-width: 1182px) {
  .home .title-section {
    padding: 120px 220px;

  }

  .home .title h1 {
    font-size: 80px;
    line-height: 80px;

  }
}

@media (max-width: 982px) {
  .home .title-section {
    padding: 120px 180px;

  }

  .home .title h1 {
    font-size: 80px;
    line-height: 80px;

  }
}

@media (max-width: 882px) {
  .home .title-section {
    padding: 120px 140px;

  }

  .home .title h1 {
    font-size: 80px;
    line-height: 80px;

  }
}

@media (max-width: 782px) {
  .home .title-section {
    padding: 100px 140px;

  }

  .home .title h1 {
    font-size: 70px;
    line-height: 70px;

  }
}

@media (max-width: 700px) {
  .home .title-section {
    padding: 90px 80px;

  }

  .home .title h1 {
    font-size: 60px;
    line-height: 60px;

  }
}

@media (max-width: 682px) {
  .home .title-section {
    padding: 90px 80px;

  }

  .home .title h1 {
    font-size: 60px;
    line-height: 60px;

  }
}

@media (max-width: 582px) {
  .home .title-section {
    padding: 90px 50px;

  }

  .home .title h1 {
    font-size: 50px;
    line-height: 50px;

  }
}

@media (max-width: 482px) {
  .home .title-section {
    padding: 90px 40px;

  }

  .home .title h1 {
    font-size: 50px;
    line-height: 50px;

  }
}

@media (max-width: 382px) {
  .home .title-section {
    padding: 70px 20px;

  }

  .home .title h1 {
    font-size: 30px;
    line-height: 30px;

  }
}

.home .text {
  padding: 0 60px;

}

@media (max-width: 782px) {
  .home .text {
    padding: 0 30px;

  }
}

@media (max-width: 482px) {
  .home .text {
    padding: 0 10px;

  }
}

.home .text p {
  color: white;
  text-align: center;
  font-weight: 200;
  font-size: 12px;
  margin-top: 20;

  font-family: 'Poppins';
}

.home .services .ant-btn-default {
  background-color: transparent !important;
  color: white !important;
  font-family: 'Poppins' !important;
  border-color: white !important;
  /* border-radius: 20px; */
  padding: 8px 15px !important;
  height: auto;
  font-weight: 200 !important;
}

.home .services .ant-btn-default:hover {
  background-color: #666EDD !important;
  -webkit-transition: background-color 500ms linear;
  -ms-transition: background-color 500ms linear;
  transition: background-color 500ms linear;
  color: white !important;
  border-color: #666EDD !important;
}

.technologies-section {
  background-color: transparent;
  display: inline-grid;
  grid-template-columns: repeat(2, 2fr);
  justify-items: center;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.main-tech-section {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 782px) {
  .technologies-section {
    grid-template-columns: repeat(1, auto);
  }
}

.technologies-section .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 10px;
  /* border: 1px solid grey; */
  padding: 85px 85px;
  color: white;
  font-family: 'Poppins';
  text-align: center;
  background-color: black;
  border-radius: 20px;
  cursor: pointer;
}

.technologies-section .item:hover{
  color: #666EDD;
  box-shadow: 0 4px 50px rgba(255, 255, 255, 0.1);
}

@media (max-width: 482px) {
  .technologies-section .item {
    padding: 40px 25px;

  }
}

.technologies-section .item p {
  font-weight: 200;
}

.technologies-section .item .icon-cont {
  /* border: 1px solid #666EDD; */
  border-radius: 500px;
  padding: 10px;
  display: flex;
  align-items: center;
  font-size: 24px;
  width: fit-content;
  color: #666EDD;
  /* padding: 25px; */
}

.technologies-section .item .icon-cont img {
  width: 60px;
}

.technologies-section .item h3 {
  margin-bottom: 20px;
  font-weight: 400;
  font-weight: 'Poppins';
}

.home .section-header {
  max-width: 700px;
  text-align: center;
  margin: 30px auto;
  width: fit-content;
  font-weight: 400;
}

.section-header h1 {
  position: relative;
  font-size: 36px;
  color: #666EDD;
  font-weight: 400;
}
@media (max-width: 482px) {
  .home .section-header h1 {
    font-size: 28px;
  }
}

.home {
  font-family: 'Poppins', sans-serif;

  --card-clr: #1e1e1e;
  --body-clr: #1e1e1e;
  --primary-clr: #f0bf6a;
  --heading-clr: #dadada;
  --text-clr: #767a86;
}

.testimonials-section {
  width: 100%;
  padding: 0px 8%;
}



.testimonials-container {
  position: relative;
}

.testimonials-container .testimonial-card {
  padding: 20px;
}

.testimonial-card .test-card-body {
  background-color: var(--card-clr);
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.12);
  padding: 40px;
  border-radius: 40px;
}

.test-card-body .quote {
  display: flex;
  align-items: center;
  color: white;
}

.test-card-body .quote svg {
  font-size: 45px;
  color: var(--heading-clr);
  margin-right: 20px;
}

.test-card-body .quote h2 {
  color: var(--heading-clr);
}

.test-card-body p {
  margin: 10px 0px 15px;
  font-size: 14px;
  line-height: 1.5;
  color: var(--text-clr);
}

.test-card-body .ratings {
  margin-top: 5px;
}

.test-card-body .ratings svg {
  font-size: 17px;
  color: var(--primary-clr);
  cursor: pointer;
}

.testimonial-card .profile {
  display: flex;
  align-items: center;
  margin-top: 25px;
}

.profile .profile-image {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px;
}

.profile .profile-image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.profile .profile-desc {
  display: flex;
  flex-direction: column;
}

.profile-desc span:nth-child(1) {
  font-size: 24px;
  font-weight: 300;
  color: #666EDD;
}

.profile-desc span:nth-child(2) {
  font-size: 15px;
  font-weight: 300;
  color: var(--text-clr);
}

.home .services {
  border-radius: 20px;
  box-shadow: 0 4px 50px rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.402);
  text-align: center;
  padding: 90px 150px;

  background-image: url("/src/Assets/Images/tech.webp");
  background-blend-mode: multiply;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-top: 20px;

}

.home .services h1 {
  color: white;
  font-size: 36px;
  font-weight: 400;
}

.home .services p {
  color: white;
  font-weight: 200;
  margin-top: 20px;
  margin-bottom: 40px;
}

@media (max-width: 782px) {
  .home .services {
    padding: 70px 100px;
  }
}

@media (max-width: 682px) {
  .home .services {
    padding: 40px 70px;
  }
}

@media (max-width: 482px) {
  .home .services {
    padding: 30px 20px;
  }

  .home .services h1 {
    font-size: 26px;
    font-weight: 400;
  }
}

.erdVAe::before, .jWQEaP::before {
  background: #666EDD !important;
}

.erdVAe::after, .jWQEaP::after {
  background: #666EDD !important;
}

.eczmnE:not(.using-icon).active, .dKXBCi:not(.using-icon).active {
  background: black !important;
  border: 3px solid #666EDD !important;
}

.eczmnE:not(.using-icon), .dKXBCi:not(.using-icon) {
  background: #666EDD !important;
}

.gJAGSz.active, .eROtBA.active {
  background: #1e1e1e !important;
  color: #666EDD !important;
  border-radius: 20px !important;
}

.gJAGSz, .eROtBA {
  background: transparent;
  color: white !important;
  font-weight: 200 !important;
  font-family: 'Poppins' !important;
  min-width: 120px !important;
  margin-right: 10px!important;
  margin-left: 10px!important;
  text-align: center!important;
}

.hthIVH, .bPpqPD {
  align-items: flex-start;
  background: #1e1e1e !important;
  border-radius: 4px;
  display: flex;
  position: absolute;
  filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.3));
  flex-direction: column;
  justify-content: flex-start;
  line-height: 1.5em;
  min-height: 0px !important;
  position: relative;
  text-align: left;
  width: fit-content !important;
  z-index: 0;
  margin-left: 10px!important;
}

.dHWHqM {
  background: #1e1e1e !important;
}

.TimelineCardContentWrapper-sc-1427v1d-2 .emqEhB .card-content-wrapper .visible .right, .TimelineCardContentWrapper-sc-1427v1d-2 .kpotpU .card-content-wrapper .visible .right {
  width: fit-content !important;
}

.home .breakdown {
  display: block;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1029px) {
  .home .breakdown {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 729px) {
  .gJAGSz, .eVosNd{
    display: none!important;
  }
}

.emqEhB {
  width: fit-content!important;
}

.hySUUY {
  display: none!important;
}

.hFrYSD, .jLfSfu, .YVkNx {
  background-color: #1e1e1e!important;
}

.home .flow-section{
  text-align: center;
  width: 160px;
  margin-left: auto;
  margin-right: auto;
  height: 200px;
  color: white;
}

.home .flow-section:hover{
  color: #666EDD;

}

.bevYHo::after, .kxuklf::after {
  content: '';
  position: absolute;
  display: none!important;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2rem;
  background: transparent!important;
}

.home .flow-section .icon{
  color: inherit;
  font-size: 30px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
}

.home .flow-section p{
  font-size: 12px;
  color: inherit;
  font-family: 'Poppins';
  font-weight: 200;
  margin-bottom: 20px;
  line-height: 14px;
}

.animate__slideInFromLeft{
  animation: slideInFromLeft 1.1s ease-out;
}

.animate__slideInFromRight{
  animation: slideInFromRight 1.1s ease-out;
}

/*Edited*/

.timeline-container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 40px;
}

.timeline-container::after {
  background-color: #666EDC;
  content: '';
  position: absolute;
  left: calc(50% - 2px);
  width: 4px;
  height: 100%;
}

.timeline-item {
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  position: relative;
  margin: 10px 0;
  width: 50%;
}

.timeline-item:nth-child(odd) {
  align-self: flex-end;
  justify-content: flex-start;
  padding-left: 30px;
  padding-right: 0;
}

.timeline-item-content {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: #1E1E1E;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 15px;
  position: relative;
  max-width: 70%;
  text-align: right;
}

.timeline-item-content::after {
  content: ' ';
  background-color: #1E1E1E;
  box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.2);
  position: absolute;
  right: -7.5px;
  top: calc(50% - 7.5px);
  transform: rotate(45deg);
  /* width: 15px;
  height: 15px; */
}

.timeline-item:nth-child(odd) .timeline-item-content {
  text-align: left;
  align-items: flex-start;
}

.timeline-item:nth-child(odd) .timeline-item-content::after {
  right: auto;
  left: -7.5px;
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
}

.timeline-item-content .tag {
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  top: 5px;
  left: 5px;
  letter-spacing: 1px;
  padding: 5px;
  position: absolute;
  text-transform: uppercase;
  padding-left: 15px;
  padding-right: 15px;
}

.timeline-item:nth-child(odd) .timeline-item-content .tag {
  left: auto;
  right: 5px;
}

.timeline-item-content p {
  font-size: 16px;
  line-height: 24px;
  margin-top: 30px;
  word-wrap: break-word;
  max-width: 400px;
}

.timeline-item-content a {
  font-size: 14px;
  font-weight: bold;
}

.timeline-item-content a::after {
  content: ' ►';
  font-size: 12px;
}

.timeline-item-content .circle {
  background-color: #0A122A;
  border: 3px solid #D9DBF1;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 10px);
  right: -40px;
  width: 20px;
  height: 20px;
  z-index: 100;
}

.timeline-item:nth-child(odd) .timeline-item-content .circle {
  right: auto;
  left: -40px;
}

@media only screen and (max-width: 1023px) {
  .timeline-item-content {
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .timeline-item-content,
  .timeline-item:nth-child(odd) .timeline-item-content {
    text-align: center;
    align-items: center;
  }
  
  .timeline-item-content .tag {
    width: calc(100% - 10px);
    text-align: center;
  }
  
  .timeline-item-content time {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 486px) {
  .timeline-item-content p{
    font-size: 10px;
    margin-top: 0px;
  }

  .timeline-container{
    margin: 15px;
  }

  .timeline-item-content .tag{
    font-size: 8px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .tag{
    font-size: 12px;
    text-align: left;
    padding: 5px 0px;
  }
}

.timeline-item-content .icon-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 20px;
}

.timeline-item-content .icon-container svg {
  font-size: 26px;
}

.timeline-item-content time,
.timeline-item-content p,
.timeline-item-content a {
  color: #fff;
}

.timeline-item-content .tag {
  color: #fff;
}