@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.our-services {
  font-family: 'Poppins';
  background-color: #121212;
}

.our-services .title {
  color: #666edd;
  font-weight: 600;
  font-size: 48px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
}

.our-services .subtitle {
  color: white;
  font-weight: 200;
  font-size: 16px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 20px;
}

@media (max-width: 1082px) {
  .our-services .title {
    font-size: 42px;
  }

  .our-services .subtitle {
    font-size: 14px;
  }
}

@media (max-width: 442px) {
  .our-services .title {
    font-size: 34px;
  }

  .our-services .subtitle {
    font-size: 12px;
  }
}

@media (max-width: 342px) {
  .our-services .title {
    font-size: 30px;
  }

  .our-services .subtitle {
    font-size: 11px;
  }
}

.our-services .line-sep {
  width: 60vw;
  height: 2px;
  background: linear-gradient(to right, transparent, #666edd, transparent);
  margin: auto;
  border-radius: 2px;
  animation: lineSepAnimation 4s ease-out 0.5;
}

@keyframes lineSepAnimation {
  0% {
    width: 0;
  }

  50% {
    width: 60vw;
  }

  100% {
    width: 0;
  }
}

.our-services .container {
  display: flex;
  gap: 20px;
  margin: 20px 0px;
  height: max-content;
}

.our-services .col-1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: auto;
  justify-content: space-between;
}

.our-services .col-2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: auto;
  justify-content: space-between;
}

.our-services .card {
  background-color: #121212;
  border: 1px solid #666edd31;
  border-radius: 10px;
  padding: 30px;
  box-shadow: #666edd16 0px 3px 5px;
  transition: transform 0.3s ease-in-out;
}

.our-services .card:hover {
  background-color: #15162162;
  transform: scale(1.01, 1.01);
  box-shadow: #666edd37 0px 3px 5px;
}

.our-services .card .card-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  text-align: center;
}

.our-services .card-body img {
  border-radius: 30px;
  display: inline;
  float: left;
}

.our-services .card-body-2 img {
  border-radius: 30px;
  height: auto;
  float: left;
  margin-right: 20px;
}

.our-services h1 {
  color: #666edd;
  text-align: center;
  margin-bottom: 15px;
  font-weight: 400;
}

.our-services p {
  color: white;
  font-weight: 200;
  margin: 0px;
  height: fit-content;
  flex: 1;
}

@media (max-width: 768px) {
  .our-services .container {
    flex-direction: column;
  }

  .our-services .card img {
    width: 80%;
    margin: 20px 0px;
  }

  .our-services .card .card-body-2 {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }

}

@media (min-width: 1441px) {
  .our-services .card .card-body-2 img {
    width: 100%;
    margin-bottom: 20px;
  }

  .our-services .card .card-body-2 {
    text-align: center;
  }

  .our-services .card .card-body-2 p {
    font-size: x-large;
    font-weight: 300;
  }

  .our-services .card .card-body p {
    font-size: x-large;
    font-weight: 300;
  }
}