@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.contact-us {
  font-family: "Poppins";
  height: fit-content;
  background-color: #121212;
}

.contact-us .title {
  color: #666edd;
  font-weight: 600;
  font-size: 48px;
  font-family: "Poppins";
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
}

.contact-us .subtitle {
  color: white;
  font-weight: 200;
  font-size: 16px;
  font-family: "Poppins";
  text-align: center;
  margin-top: 0px;
  margin-bottom: 20px;
}

@media (max-width: 1082px) {
  .contact-us .title {
    font-size: 42px;
  }

  .contact-us .subtitle {
    font-size: 14px;
  }
}

@media (max-width: 442px) {
  .contact-us .title {
    font-size: 34px;
  }

  .contact-us .subtitle {
    font-size: 12px;
  }
}

@media (max-width: 342px) {
  .contact-us .title {
    font-size: 30px;
  }

  .contact-us .subtitle {
    font-size: 11px;
  }
}

.contact-us .line-sep {
  width: 60vw;
  height: 2px;
  background: linear-gradient(to right, transparent, #666edd, transparent);
  margin: auto;
  border-radius: 2px;
  animation: lineSepAnimation 4s ease-out 0.5;
}

@keyframes lineSepAnimation {
  0% {
    width: 0;
  }
  50% {
    width: 60vw;
  }
  100% {
    width: 0;
  }
}

.contact-us .page-content {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding-top: 40px;
}

.contact-us .left {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contact-us .contact-box {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 30px;
  width: fit-content;
  height: fit-content;
}

.contact-us .box {
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.contact-us .icon-container {
  height: fit-content;
  width: fit-content;
  padding: 10px;
  border: 2px solid #666edd;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-us svg {
  width: 50px;
  height: 50px;
  color: #666edd;
}

.contact-us .info {
  display: flex;
  flex-direction: column;
  gap: 0px;
  font-family: "Poppins";
  width: fit-content;
  color: white;
}

.contact-us .box-title {
  font-size: 20px;
  font-weight: 600;
  color: #666edd;
}

.contact-us .right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-us .message-box {
  width: 500px;
  padding: 20px 30px;
  background-color: #1e1e1e;
  border-radius: 20px;
}

.contact-us .ant-form-item-required {
  font-family: "Poppins";
  color: white !important;
}

.contact-us .message-box .ant-input {
  border-radius: 10px;
  padding: 10px;
  background-color: #2c2c2c;
  border: none;
  font-family: "Poppins";
  color: #ffffff;
  font-weight: 200;
  font-family: "Poppins";
}

.contact-us .message-box .ant-input:focus {
  font-family: "Poppins";
  border: 1px solid #666edd;
}

.contact-us .ant-input::placeholder,
.contact-us .ant-input-textarea::placeholder {
  color: #7c7c7c;
}

.contact-us .message-box h2 {
  text-align: center;
  margin-bottom: 20px;
  font-family: "Poppins";
  color: #666edd;
}

.contact-us .center {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.contact-us .send-btn {
  margin: 0 auto;
  background-color: #666edd;
  width: 100%;
  color: #fff;
  border: none;
  border-radius: 40px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.6);
}

.contact-us .send-btn:hover {
  background-color: #434dde !important;
}

.contact-us .spin-center{
  width: 100%;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 1000px) {
  .contact-us .page-content {
    flex-direction: column;
  }

  .contact-us svg {
    width: 30px;
    height: 30px;
    color: #666edd;
  }

  .contact-us .info {
    display: flex;
    flex-direction: column;
    gap: 0px;
    font-family: "Poppins";
    color: white;
  }

  .contact-us .box-title {
    font-size: 16px;
    font-weight: 600;
    color: #666edd;
  }

  .contact-us .box-val {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contact-us .contact-us .contact-box {
    align-items: center;
    width: fit-content;
    justify-content: center;
  }
}
@media screen and (max-width: 530px) {
  .contact-us .message-box {
    width: 100%;
  }
}
