@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.portfolio {
  font-family: "Poppins";
  height: fit-content;
  background-color: transparent;
}

.portfolio .title {
  font-family: "Poppins";
  color: #666edd;
  font-weight: 600;
  font-size: 48px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
}

.portfolio .subtitle {
  color: white;
  font-family: "Poppins";
  font-weight: 200;
  font-size: 16px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 20px;
}

@media (max-width: 1082px) {
  .portfolio .title {
    font-size: 42px;
  }

  .portfolio .subtitle {
    font-size: 14px;
  }
}

@media (max-width: 442px) {
  .portfolio .title {
    font-size: 34px;
  }

  .portfolio .subtitle {
    font-size: 12px;
  }
}

@media (max-width: 342px) {
  .portfolio .title {
    font-size: 30px;
  }

  .portfolio .subtitle {
    font-size: 11px;
  }
}

.portfolio .line-sep {
  width: 60vw;
  height: 2px;
  background: linear-gradient(to right, transparent, #666edd, transparent);
  margin: auto;
  border-radius: 2px;
  animation: lineSepAnimation 4s ease-out 0.5;
}

@keyframes lineSepAnimation {
  0% {
    width: 0;
  }
  50% {
    width: 60vw;
  }
  100% {
    width: 0;
  }
}

.portfolio .page-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 40px;
}

.portfolio .project-section {
  display: flex;
  flex-direction: row;
  padding: 20px 5vw;
  gap: 30px;
}

.portfolio .project-section-reverse {
  text-align: right;
  display: flex;
  flex-direction: row;
  padding: 20px 5vw;
  gap: 30px;
}

.portfolio .project-left {
  height: fit-content;
  display: flex;
  align-items: center;
}

.portfolio .mockup-container {
  border-radius: 40px;
  width: 500px;
  height: auto;
}

.portfolio .mockup-img {
  border-radius: 40px;
  width: 100%;
  height: auto;
  display: block;
  margin: auto;
}

.portfolio .project-right {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
}

.portfolio .project-title {
  font-family: "Poppins";
  color: #666edd;
  font-weight: 400;
  font-size: 32px;
  margin: 0;
}

.portfolio .link {
  font-size: 12px;
  font-weight: 300;
  text-decoration: underline;
}

.portfolio .project-desc {
  font-family: "Poppins";
  color: white;
  font-weight: 200;
  font-size: 16px;
  margin: 0px;
}

.portfolio .technologies {
  background-color: transparent;
  align-items: flex-start;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 20px;
}

.portfolio .techno {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.portfolio .techno-image {
  width: 60px;
  height: 60px;
  display: block;
  margin: auto;
}

.portfolio .techno-name {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  color: white;
  font-weight: 300;
  font-size: 16px;
  margin-bottom: 10px;
}

@media (max-width: 1082px) {
  .portfolio .mockup-container {
    width: 400px;
    height: auto;
  }
}

@media (max-width: 900px) {
  .portfolio .mockup-container {
    width: 100%;
    height: auto;
  }
  .portfolio .project-section {
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 20px 5vw;
    gap: 30px;
  }

  .portfolio .project-section-reverse {
    text-align: center;
    display: flex;
    flex-direction: column-reverse;
    padding: 20px 5vw;
    gap: 30px;
  }

  .portfolio .project-left {
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 700px) {
  .portfolio .technologies {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 30px;
    justify-content: flex-start;
    overflow-x: scroll;
    margin-top: 20px;
  }

  .portfolio .techno-name {
    font-size: 10px;
  }

  .portfolio .technologies::-webkit-scrollbar {
    width: 0px;
  }
}

@media (min-width: 1800px) {
  .portfolio .mockup-container {
    width: 1000px;
    height: auto;
  }

  .portfolio .project-title {
    font-family: "Poppins";
    color: #666edd;
    font-weight: 400;
    font-size: 40px;
    margin: 0;
  }

  .portfolio .project-desc {
    font-family: "Poppins";
    color: white;
    font-weight: 200;
    font-size: 28px;
    margin: 0px;
  }
}
