.technologies {
  color: #666edd;
}


.technologies .title {
  color: #666edd;
  font-weight: 600;
  font-size: 48px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
}

.technologies .subtitle {
  color: white;
  font-weight: 200;
  font-size: 16px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 20px;
}

@media (max-width: 1082px) {
  .technologies .title {
    font-size: 42px;
  }

  .technologies .subtitle {
    font-size: 14px;
  }
}

@media (max-width: 442px) {
  .technologies .title {
    font-size: 34px;
  }

  .technologies .subtitle {
    font-size: 12px;
  }
}

@media (max-width: 342px) {
  .technologies .title {
    font-size: 30px;
  }

  .technologies .subtitle {
    font-size: 11px;
  }
}

.technologies .line-sep {
  width: 60vw;
  height: 2px;
  background: linear-gradient(to right, transparent, #666edd, transparent);
  margin: auto;
  border-radius: 2px;
  animation: lineSepAnimation 4s ease-out 0.5;
}

@keyframes lineSepAnimation {
  0% {
    width: 0;
  }

  50% {
    width: 60vw;
  }

  100% {
    width: 0;
  }
}

.technologies .container {
  margin: 25px 0px;
  height: max-content;
}

.technologies .card {
  background-color: #121212;
  border: 1px solid #666edd31;
  border-radius: 10px;
  padding: 30px;
  box-shadow: #666edd16 0px 3px 5px;
  transition: transform 0.3s ease-in-out;
  height: 100%;
}

.technologies .card:hover {
  background-color: #15162162;
  transform: scale(1.01, 1.01);
  box-shadow: #666edd37 0px 3px 5px;
}

.technologies h1 {
  color: #666edd;
  text-align: center;
  margin-bottom: 15px;
  font-weight: 400;
}

.technologies p {
  color: white;
  font-weight: 200;
  margin: 0px;
  height: fit-content;
  flex: 1;
}

.technologies .card img {
  float: left;
  margin-right: 15px;
  height: 100px;
  width: auto;
}