@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.career {
  font-family: "Poppins";
  height: fit-content;
  background-color: transparent;
}

.career .title {
  font-family: "Poppins";
  color: #666edd;
  font-weight: 600;
  font-size: 48px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
}

.career .subtitle {
  color: white;
  font-family: "Poppins";
  font-weight: 200;
  font-size: 16px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 20px;
}

@media (max-width: 1082px) {
  .career .title {
    font-size: 42px;
  }

  .career .subtitle {
    font-size: 14px;
  }
}

@media (max-width: 442px) {
  .career .title {
    font-size: 34px;
  }

  .career .subtitle {
    font-size: 12px;
  }
}

@media (max-width: 342px) {
  .career .title {
    font-size: 30px;
  }

  .career .subtitle {
    font-size: 11px;
  }
}

.career .line-sep {
  width: 60vw;
  height: 2px;
  background: linear-gradient(to right, transparent, #666edd, transparent);
  margin: auto;
  border-radius: 2px;
  animation: lineSepAnimation 4s ease-out 0.5;
}

@keyframes lineSepAnimation {
  0% {
    width: 0;
  }
  50% {
    width: 60vw;
  }
  100% {
    width: 0;
  }
}

.career .page-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 20px;
}

.career .left {
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.career .join-us {
  flex: 1;
}

.career .join-us-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.career .join-us-image img {
  width: 70%;
}

.career .join-us,
.career .join-us-image {
  opacity: 0;
  transform: translateX(-50%);
  transition: opacity 1s, transform 1s;
}

.career .join-us.animate,
.career .join-us-image.animate {
  opacity: 1;
  transform: translateX(0);
}

/* Add the following animation styles */
@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.career .join-us-image.animate {
  animation: slideInFromLeft 1.1s ease-out;
}

.career .join-us.animate {
  animation: slideInFromRight 1.1s ease-out;
}

.career .career .right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.career .benefits-section {
  text-align: left;
}

.career .section-title {
  font-family: "Poppins";
  color: #666edd;
  font-weight: 400;
  font-size: 28px;
  margin-bottom: 20px;
}

.career .benefits-list {
  list-style-type: none;
  padding: 0;
}

.career .benefits-list li {
  font-family: "Poppins";
  color: white;
  font-weight: 200;
  font-size: 16px;
  margin-bottom: 10px;
}

.career .button-apply {
  color: #666edd;
  font-weight: 200;
  font-family: "Poppins";
  padding: 10px 20px !important;
  height: auto !important;
  background: transparent;
  border: 1px solid #666edd;
}

.career .button-apply:hover {
  background-color: #666edd;
  border: 0.5px solid #666edd !important;
  color: white !important;
}

.career .message-box {
  width: 100%;
  padding: 20px 30px;
  background-color: #1e1e1e;
  border-radius: 20px;
}

.career .ant-form-item-required {
  font-family: "Poppins";
  color: white !important;
}

.career .message-box .ant-input {
  border-radius: 10px;
  padding: 10px;
  background-color: #2c2c2c;
  border: none;
  font-family: "Poppins";
  color: #ffffff;
  font-weight: 200;
  font-family: "Poppins";
}

.career .message-box .ant-input:focus {
  font-family: "Poppins";
  border: 1px solid #666edd;
}

.career .ant-input::placeholder,
.career .ant-input-textarea::placeholder {
  color: #7c7c7c;
}

.career .message-box h2 {
  text-align: center;
  margin-bottom: 20px;
  font-family: "Poppins";
  color: #666edd;
}

.career .center {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.career .send-btn {
  margin: 0 auto;
  background-color: #666edd;
  width: 30vw;
  color: #fff;
  border: none;
  border-radius: 40px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.6);
}

.career .send-btn:hover {
  background-color: #434dde !important;
}

@media screen and (max-width: 1000px) {
  .career .page-content {
    flex-direction: column;
  }
}

.career .ant-select {
  height: fit-content;
}

.career .ant-select-selection-placeholder {
  color: #7c7c7c !important;
}

.career .ant-select-arrow {
  color: #7c7c7c;
}

.career .ant-select-selector {
  border-radius: 10px !important;
  padding: 6px 10px !important;
  background-color: #2c2c2c !important;
  border: none !important;
  font-family: "Poppins" !important;
  color: #ffffff !important;
  font-weight: 200 !important;
}

.career .ant-select-selector:hover {
  border: 1px solid #666edd !important;
}

.career .ant-select-selection:focus {
  border: 1px solid #666edd !important;
}

.career .ant-select-selector-open {
  border: 1px solid #666edd !important;
}

.career .ant-select-item-option {
  font-family: "Poppins";
  color: #ffffff;
  background-color: #2c2c2c !important;
}

.career .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #666edd;
}

.career .form-inline-items {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.career .form-inline-item {
  flex: 1;
}

@media screen and (max-width: 575px) {
  .career .form-inline-items {
    flex-direction: column;
    gap: 0;
  }
}

/* @media screen and (max-width: 1100px) {
  .career .join-us-image img {
    width: 100%;
  }
} */

@media screen and (max-width: 900px) {
  .career .join-us-image img {
    width: 100%;
  }
}

@media screen and (max-width: 700px) {
  .career .join-us-image {
    display: none;
  }

  .career .button-holder {
    display: flex;
    justify-content: center;
  }

  .career .benefits-section {
    text-align: justify;
  }

  .career .section-title {
    text-align: center;
  }
}
