@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.about {
  font-family: "Poppins";
  height: fit-content;
  background-color: #121212;
}

.about .title {
  color: #666edd;
  font-weight: 600;
  font-size: 48px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
}

.about .subtitle {
  color: white;
  font-weight: 200;
  font-size: 16px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 20px;
}

@media (max-width: 1082px) {
  .about .title {
    font-size: 42px;
  }

  .about .subtitle {
    font-size: 14px;
  }
}

@media (max-width: 442px) {
  .about .title {
    font-size: 34px;
  }

  .about .subtitle {
    font-size: 12px;
  }
}

@media (max-width: 342px) {
  .about .title {
    font-size: 30px;
  }

  .about .subtitle {
    font-size: 11px;
  }
}

.about .line-sep {
  width: 60vw;
  height: 2px;
  background: linear-gradient(to right, transparent, #666edd, transparent);
  margin: auto;
  border-radius: 2px;
  animation: lineSepAnimation 4s ease-out 0.5;
}

@keyframes lineSepAnimation {
  0% {
    width: 0;
  }
  50% {
    width: 60vw;
  }
  100% {
    width: 0;
  }
}

.about .about-body {
  color: white;
  font-size: 16px;
  font-weight: 200;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 100px;
  padding-right: 100px;
}

.about .about-body .top {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.about .about-body .mid {
  width: 1fr;
  margin-top: 40px;
  margin-bottom: 20px;
}

.about .about-body .mid img {
  width: fill;
  height: fill;
  border: 2.5px solid white;
}

.about .about-body .bot {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.about .about-body .bot .bot-section .bot-section-head {
  display: flex;
  padding: 25px 0px;
  justify-content: center;
  gap: 10px;
  align-items: center;
}

.about .about-body .bot .bot-section h2 {
  text-align: center;
  font-family: "Poppins";
  font-weight: 400;
  margin: 0;
  display: inline;
  color: #666edd;
}

.about .about-body .bot .bot-section .bot-section-head .bot-section-logo {
  display: flex;
  align-items: center;
  color: #666edd;
}

.about .about-body .bot .bot-section .bot-section-body {
  text-align: center;
}

.about .about-body .bot .bot-section img {
  width: 50px;
}

@media (max-width: 1100px) {
  .about .about-body {
    font-size: 16px;
    padding: 0px;
  }
}

@media (max-width: 850px) {
  .about .about-body {
    font-size: 15px;
    padding: 0px;
  }

  .about .about-body .bot .bot-section h2 {
    font-size: 20px;
  }

  .about .about-body .bot .bot-section img {
    width: 40px;
  }
}

@media (max-width: 670px) {
  .about .about-body {
    text-align: justify;
  }

  .about .about-body .top {
    grid-template-columns: 1fr;
    gap: 0px;
  }

  .about .about-body .top .right p {
    margin-top: 0px;
  }

  .about .about-body .bot {
    grid-template-columns: 1fr;
    gap: 0px;
  }

  .about .about-body .bot .bot-section .bot-section-head {
    padding: 20px;
    gap: 0px;
  }

  .about .about-body .bot .bot-section h2 {
    font-size: 25px;
  }
}

@media (max-width: 420px) {
  .about .about-body {
    text-align: justify;
    margin-top: 20px;
    font-size: 12px;
    padding: 0px;
  }
}
